import React from "react";
import "../styles/Calendaire.css";

export default function Calendaire() {
     return (
          <div style="{{" padding:="" "0%="" 1%",="" }}="">
               <p style="{{" textTransform:="" "uppercase",="" fontSize:="" "17px",="" fontWeight:="" "500",="" letterSpacing:="" "11px",="" margin:="" "o%",="" }}="">
                    即将推出
               </p>

               <ul style="{{" margin:="" "0%",="" listStyle:="" "none",="" padding:="" }}="">
                    <li>
                         2025 - 弦间项目（德国）的新项目。
                         为 4 把电吉他创作的新作品。
                    </li>
                    <li>
                         12/04/2025 - 新多媒体项目 "注入 3'600'000ms
                         项目，由 Zagreg Music Bienale 委托制作、
                         作为 ZMB25（瑞士）的一部分，由 Elision 乐团首演。
                    </li>
                    <li>
                         tbc/01/2025 - Infused 3'600'000ms（缩减版）、
                         新多媒体项目，ICST 博士预科项目的一部分
                         由约书亚-海德和弗朗西斯科-乌贝托首演。
                         乌伯托
                    </li>
                    <li>
                         24/10/24 我所有的神经元都有阴蒂，Flock 实验室、
                         阿根廷驻巴黎大使，巴黎（法国）
                    </li>
                    <li>
                         17/10/24 我所有的神经元都有阴蒂，Flock Lab, CRD de
                         Pantin, Pantin (FR)
                    </li>
                    <li>
                         15/10/24LIFE electronics, Émilie Girard-Charest、
                         巴黎，凤凰城（法国）
                    </li>

                    {/* <li>
                         28/06/24 1Up - solo set，Gallerie du Haut Pavé，巴黎
                         法国
                    </li> */}
                    {/* <li>
                         15/05/24 K.A.M.I.K.A.Z.E. B-L 双人乐队在柏林 Kater Blau 演出
                         柏林，作为 classicalNEXT '24（德国）的一部分
                    </li>
                    <li>
                         27/04/24 K.A.M.I.K.A.Z.E.B-L二重奏，音乐厅
                         (市政厅）新加坡（SG）
                    </li> */}
                    {/* <li>
                         15/03/24Paradise (or how I'll get there from a door  on the third floor).
                         三楼），Salle d'orchestre à la HEAR、
                         斯特拉斯堡（法国）
                    </li>
                    <li>
                         02/02/24 -LIFE electronics.
                         洞穴 à son "工作室（即将发布更多信息），巴黎（法国）
                    </li>
                    <li>
                         11/01/24 - 我一个人 - 独奏表演 - 巴黎阿根廷之家（法国
                         阿根廷之家 - 巴黎（法国）
                    </li>

                    <li>
                         25/11/23 - K.A.M.I.K.A.Z.E - B-L duo - hcfm//音乐节
                         英国
                    </li>
                    <li>
                         11/11/23 - P.A.R.A.D.I.S.E. - Li Li-Chin - C-Lab - 台北 (TW)
                         台北
                    </li>
                    <li>
                         26/10/23 -Elettrificare il Rosa - Métallique 三重奏 - 巴黎国际艺术中心（法国
                         巴黎国际艺术城（法国）
                    </li> */}
               </ul>
          </div>
     );
}
